<template>
  <Slide class="side-menu" right>
    <DxaLink
      :href="`${envUrl}auth`"
      target="_blank"
      text="Entrar"
      size="large"
    />
    <DxaLink
      :href="`${envUrl}auth/welcome-page?partner=${tcPartnerName}`"
      target="_blank"
      text="Criar Carteira"
      size="large"
    />
  </Slide>
</template>

<script>
import { Slide } from "vue-burger-menu";
// esse cara não está no @components pq ele é bem especifico da landing page
import DxaLink from "@/components/dxa/Link/DxaLink.vue";
export default {
  name: "SideMenu",
  components: {
    Slide,
    DxaLink,
  },
  data() {
    return {
      tcPartnerName: "TradersClub",
      envUrl: process.env.VUE_APP_BASE_URL_FRONT,
    };
  },
};
</script>

<style>
.bm-menu {
  background-color: var(--dark);
}

.bm-burger-bars {
  background-color: var(--primary);
  height: 3px;
}

.bm-cross {
  background-color: var(--primary);
}

.bm-burger-button {
  top: 8%;
}

.bm-item-list {
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
