<template>
  <a
    class="dxa-link"
    :class="type === 'filled' ? 'dxa-link-filled' : ''"
    :style="className"
    :href="href"
    :target="target"
    >{{ text }}</a
  >
</template>

<script>
export default {
  name: "HeaderMenuLink",
  props: {
    type: {
      type: String,
      default: "filled",
    },
    target: {
      type: String,
      default: "_blank",
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      className: "",
    };
  },
  created() {
    this.className = this.getLinkStyle();
  },
  methods: {
    getLinkStyle() {
      return this.checkType(this.type);
    },
    checkType(type) {
      const types = {
        outlined: "border: 1px solid var(--primary); color: var(--primary);",
        filled: "background-color: var(--primary); color: #fff;",
      };
      return (
        types[type] ||
        "border: 1px solid var(--primary); color: var(--primary);"
      );
    },
  },
};
</script>

<style scoped>
.dxa-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
  transition: 0.2s;
}

a.dxa-link:hover {
  background-color: var(--primary);
  color: var(--white) !important;
}

a.dxa-link.dxa-link-filled:hover {
  filter: drop-shadow(0px 4px 4px var(--primary));
}
</style>
