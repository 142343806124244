<template>
  <div>
    <div class="header">
      <img src="../../../assets/DXA_Invest-logo.png" class="logo" alt="logo" />
      <div v-if="display === displayEnum.SMALL">
        <SideMenu />
      </div>
      <div v-if="display !== displayEnum.SMALL" class="button-group">
        <HeaderMenuLink
          :href="`${envUrl}auth`"
          target="_self"
          text="Entrar"
          type="outlined"
        />
        <HeaderMenuLink
          :href="`${envUrl}auth/register-client?partner=${tcPartnerName}`"
          target="_self"
          text="Criar Carteira"
          type="filled"
          class="create-wallet"
        />
      </div>
    </div>
    <div class="container">
      <section class="section1">
        <div class="section1-content">
          <div class="header-text">
            <span>Invista nas</span>
            <span class="primary-text">melhores empresas fora da Bolsa</span>
          </div>
          <p class="header-subtitle">
            Comece a investir em empresas privadas de forma guiada e segura.
          </p>
          <div class="redirect-button-container">
            <HeaderMenuLink
              :href="`${envUrl}auth/register-client?partner=${tcPartnerName}`"
              target="_self"
              text="Conheça nossas oportunidades"
              type="filled"
              class="link-filled-size"
            />
          </div>

          <div class="row partner-row">
            <img
              src="../../../assets/tc-landing/tc-logo.svg"
              class="img"
              alt="img"
            />
            <span class="primary-text">+</span>
            <img
              src="../../../assets/tc-landing/logo-dxa.svg"
              class="img"
              alt="img"
            />
          </div>
          <div class="partner-footer-content">
            <span class="primary-text partner-footer"
              >Parceiro Oficial de Private Equity e Venture Capital</span
            >
          </div>
        </div>
        <div class="card-container">
          <div class="header-label">
            <h2 class="card-header-title">
              O mercado que supera a bolsa de valores há 20 anos
            </h2>
            <div class="card-header-text">
              <p>
                Investimentos em private equity tem uma performance histórica
                quase <span class="card-header-text-bold">4x maior</span> que as
                500 melhores ações da bolsa americana.
              </p>
            </div>
            <div class="graphic-container">
              <img src="../../../assets/landing-graphic.svg" alt="Gráfico" />
            </div>
          </div>
        </div>
      </section>
      <section class="section2">
        <div class="section2-content">
          <img
            src="../../../assets/tc-landing/xx.png"
            class="img-section2"
            alt="img"
          />
          <div class="number-group">
            <div class="number-container">
              <span class="number">+R$ 1Bilhão</span>
              <span class="number-label"> sob gestão </span>
            </div>
            <div class="number-container">
              <span class="number">90%</span>
              <span class="number-label"> de investidores globais </span>
            </div>
            <div class="number-container">
              <span class="number">R$ 50k</span>
              <span class="number-label"> aporte mínimo </span>
            </div>
          </div>
        </div>
      </section>
      <section class="section3">
        <div class="embed-video-container">
          <iframe
            :src="`https://player.vimeo.com/video/674535050`"
            frameborder="0"
            :style="`width: ${embedVideoWidth}px; height: ${embedVideoHeight}px;`"
            allowfullscreen
          ></iframe>
          <div class="how-it-works-container">
            <span class="how-it-works">Como funciona?</span>
            <span class="how-it-works-label">
              A <span class="primary-text"> DXA Invest </span> oferece um
              programa de investimento com uma
              <span class="primary-text">
                gestão completamente exclusiva e personalizada</span
              >, visando oferecer as oportunidades que melhor se adequam ao
              perfil mapeado do investidor.
            </span>
            <div class="redirect-button-container">
              <HeaderMenuLink
                :href="`${envUrl}auth/register-client?partner=${tcPartnerName}`"
                target="_self"
                text="Crie sua Carteira Alpha"
                type="filled"
                class="link-filled-size"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="section4">
        <span class="steps-label">Passo a passo</span>
        <div class="steps-row">
          <div class="step-container">
            <div class="step-number-container">
              <span class="step-number">1</span>
            </div>
            <span class="step-label"> Crie sua Carteira Alpha </span>
            <span class="step-description"
              >Escolha o quanto deseja investir em um ano para definir o nível
              da sua Carteira Alpha.</span
            >
          </div>
          <div class="step-container">
            <div class="step-number-container">
              <span class="step-number">2</span>
            </div>
            <span class="step-label"> Faça seu primeiro check-in </span>
            <span class="step-description"
              >Após criar sua carteira, você será apresentado ao seu gestor DXA
              exclusivo. Ele irá fazer o mapeamento do seu perfil de
              investimento e composição da sua Carteira Alpha.
              <span class="step-description-break-line">
                É com base nessas informações que a DXA vai sugerir alocações em
                empresas privadas adequadas ao seu portfólio.</span
              >
            </span>
          </div>
          <div class="step-container">
            <div class="step-number-container">
              <span class="step-number">3</span>
            </div>
            <span class="step-label"> Realize sua primeira alocação </span>
            <span class="step-description"
              >Depois do seu primeiro mapeamento de perfil de investimento, seu
              gestor recomendará a primeira alocação da sua carteira.
              <span class="step-description-break-line">
                Pela plataforma você poderá acompanhar o crescimento da empresa
                investida através de vídeos e relatórios preparados pelo nosso
                time de Execução.</span
              >
            </span>
          </div>
        </div>

        <div class="redirect-footer-container">
          <HeaderMenuLink
            :href="`${envUrl}auth/register-client?partner=${tcPartnerName}`"
            target="_self"
            text="Crie sua Carteira Alpha"
            type="filled"
            class="link-filled-size"
          />
        </div>
      </section>
      <footer class="footer">
        <div class="footer-row">
          <div class="footer-logos">
            <img
              src="../../../assets/DXA_Invest-logo.png"
              class="logo"
              alt="logo"
            />
            <a href="https://br.linkedin.com/company/dxainvest" target="_self">
              <img
                class="social-logo"
                src="../../../assets/tc-landing/linkedin.svg"
              />
            </a>
          </div>
          <div class="footer-label-containter">
            <span class="footer-label"
              >A melhor forma de investir fora da bolsa.</span
            >
          </div>
        </div>
        <span class="footer-c"
          >© DXA Invest 2022• Todos os direitos reservados •
          <DxaLink
            href="https://www.dxainvest.com/legal/politica-de-privacidade"
            target="_self"
            text="Política de Privacidade"
            size="small"
        /></span>
      </footer>
    </div>
  </div>
</template>

<script>
import DxaLink from "@/components/dxa/Link/DxaLink.vue";
import SideMenu from "./SideMenu.vue";
import HeaderMenuLink from "./HeaderMenuLink";

const displayEnum = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};
const displayBreakpoints = {
  small: {
    embedVideoWidth: 320,
    embedVideoHeight: 180,
  },
  medium: {
    embedVideoWidth: 400,
    embedVideoHeight: 225,
  },
  large: {
    embedVideoWidth: 550,
    embedVideoHeight: 315,
  },
};

export default {
  name: "TC",
  components: {
    DxaLink,
    SideMenu,
    HeaderMenuLink,
  },
  data: () => ({
    display: displayEnum.MEDIUM,
    displayEnum: displayEnum,
    embedVideoWidth: displayBreakpoints.large.embedVideoWidth,
    embedVideoHeight: displayBreakpoints.large.embedVideoHeight,
    tcPartnerName: "TradersClub",
    envUrl: process.env.VUE_APP_BASE_URL_FRONT,
  }),
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    let investorFormData = {
      ...this.$route.query,
    };
    localStorage.setItem("investorFormData", JSON.stringify(investorFormData));
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    redirectAlphaSignUp() {
      this.$router.push("/auth/register-client?partner=TradersClub");
    },
    onResize() {
      if (window.innerWidth < 768) {
        this.display = displayEnum.SMALL;
        this.embedVideoWidth = displayBreakpoints.small.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.small.embedVideoHeight;
      } else if (window.innerWidth < 1024) {
        this.display = displayEnum.MEDIUM;
        this.embedVideoWidth = displayBreakpoints.medium.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.medium.embedVideoHeight;
      } else {
        this.display = displayEnum.LARGE;
        this.embedVideoWidth = displayBreakpoints.large.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.large.embedVideoHeight;
      }
    },
  },
};
</script>

<style scoped>
.redirect-button-container {
  width: 42%;
  margin-top: 40px;
}

.redirect-footer-container {
  margin: 40px auto 0 auto;
}

.header {
  background-color: var(--dark);
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 0 50px;
}

.logo {
  width: 200px;
}

.button-group {
  display: flex;
  width: 15%;
  gap: 50px;
  margin-right: 1.25rem;
}

.header-button {
  border-radius: 10px;
}

.button-label-white {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
}

.button-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

/* standardize buttons type full */
.link-filled-size {
  min-width: 324px;
  max-width: max-content;
  padding: 15px 20px;
}

.container {
  height: 100vh;
  background-color: var(--dark);
  margin: 0;
  padding: 40px 0;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.section1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-image: url("../../../assets/tc-landing/section1-background.svg"),
    url("../../../assets/tc-landing/section1-background2.svg");
  background-position: bottom left, top right;
  background-repeat: no-repeat;
  background-color: var(--dark);
}

.row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.primary-text {
  color: var(--primary);
  font-family: Source Sans Pro;
}

.header-text {
  font-family: Source Sans Pro;
  font-weight: bold;
  font-size: 35px;
  color: var(--white);
}

.header-text :nth-child(1) {
  margin-right: 8px;
}

.header-subtitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-top: 8px;
}

.section1-content {
  width: 540px;
}

.action-button {
  font-family: Source Sans Pro;
  margin-top: 40px;
  font-size: 18px;
}

.partner-row {
  margin-top: 30px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
  padding-left: 40px;
}

.partner-footer {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
}

.partner-footer-content {
  width: 50%;
  text-align: center;
  margin-top: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background: var(--white);
  border-radius: 8px;
  padding: 30px 0;
  max-width: 520px;
  min-height: 549px;
}

.header-label {
  text-align: center;
  padding: 0 40px;
}

.card-header-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.card-header-text {
  margin-top: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  display: flex;
  flex-direction: column;
}

.card-header-text-bold {
  font-family: Source Sans Pro;
  font-weight: bold;
}

.card-content-row {
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 80%;
}

.company-name {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-growth {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-details {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
}

.progress-border {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-secondary {
  width: 9%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
  display: flex;
  align-items: center;
}

.progress-secondary-19 {
  width: 19%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
}

.progress-secondary-5 {
  width: 5%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
}

.progress-primary {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background-color: var(--primary);
}

.company-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.company-market-compare-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;
}

.company-market-compare {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-market-compare-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-market-compare-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.market-sources {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.market-sources-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-left: 5px;
}

.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.separator {
  border: 1px solid var(--dark);
  width: 100%;
  margin: 20px 0;
}

.section2 {
  display: flex;
  padding: 30px 15px;
  background-color: var(--dark);
}

.section3 {
  display: flex;
  background-color: var(--dark);
  padding: 30px 0;
  justify-content: space-around;
  background-image: url("../../../assets/tc-landing/bg-video.svg"),
    url("../../../assets/tc-landing/xx.png");
  background-position: 0 20%, bottom right 5%;
  background-repeat: no-repeat;
  height: 600px;
}

.section2-content {
  display: flex;
  width: 100%;
}

.number-container {
  display: flex;
  flex-direction: column;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.number-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
}

.number-group {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 20px;
  justify-content: space-around;
}

.embed-video-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.how-it-works-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 35px;
  width: 40%;
}

.how-it-works {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.how-it-works-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-top: 8px;
}

.video-action-button {
  width: 326px;
}

.section4 {
  display: flex;
  padding: 30px 0;
  background-color: var(--dark);
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.steps-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.steps-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 23%;
  margin-top: 30px;
  height: 10%;
}

.step-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-top: 20px;
}

.step-number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.step-number {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.step-description {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-top: 8px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: var(--dark);
}

.content {
  display: flex;
  flex-direction: column;
}

.footer-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

.footer-c {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.footer-label-containter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logos {
  display: flex;
  flex-direction: column;
}

.social-logo {
  margin-top: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* to break the text of step-description */
.step-description-break-line {
  display: block;
  margin-top: 1rem;
}

.create-wallet {
  white-space: nowrap !important;
}

.graphic-container img {
  max-width: max-content;
  margin-top: 60px;
}

@media only screen and (min-width: 769px) and (max-width: 1120px) {
  .header {
    justify-content: space-around;
  }

  .redirect-button-container {
    width: 100%;
  }

  .container {
    height: auto;
  }

  .section1 {
    flex-direction: column;
    padding: 25px;
    background-position: 130% 42%, 110% 100%;
    background-repeat: no-repeat;
  }

  .card-container {
    justify-self: center;
    align-self: center;
  }

  .section1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .link-filled-size {
    min-width: 400px;
    margin: 0 auto;
  }

  .img-section2 {
    display: none;
  }

  .section3 {
    flex-direction: column;
    padding: 30px 0;
    background-image: url("../../../assets/tc-landing/bg-video.svg");
    background-position: 0 45%;
    background-repeat: no-repeat;
  }

  .embed-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .embed-video-container > a {
    justify-self: center;
  }

  .how-it-works-label {
    max-width: 600px;
  }

  .how-it-works-container {
    width: 100%;
    display: flex;
    align-items: center;
    align-items: center;
    text-align: center;
  }

  .section4 {
    flex-direction: column;
  }

  .steps-row {
    flex-direction: column;
    align-items: center;
  }

  .step-container {
    width: 100%;
    padding: 15px 30px;
    margin-top: 0;
  }

  .steps-label {
    display: none;
  }

  .step-description {
    max-width: 720px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    justify-content: space-around;
  }

  .redirect-button-container {
    width: 100%;
  }

  .card-container {
    justify-self: center;
    align-self: center;
  }

  .redirect-footer-container {
    margin: 40px 0 0 0;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container {
    height: auto;
  }

  .section1 {
    flex-direction: column;
    padding: 25px;
    background-image: url("../../../assets/tc-landing/section1-background.svg"),
      url("../../../assets/tc-landing/section1-background2.svg");
    background-position: 130% 58%, 110% 100%;
    background-repeat: no-repeat;
  }

  .header-text {
    text-align: center;
  }

  .header-subtitle {
    text-align: center;
  }

  .section1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partner-row {
    width: 100%;
    justify-content: space-evenly;
  }

  .partner-footer-content {
    width: 100%;
    margin-bottom: 55px;
  }

  .partner-footer {
    font-size: 16px;
  }

  .header-label {
    width: 100%;
    text-align: center;
    padding: 0px 10px;
  }

  .img-section2 {
    display: none;
  }

  .number-group {
    display: flex;
    flex-direction: column;
  }

  .number-container {
    margin-bottom: 40px;
  }

  .section3 {
    flex-direction: column;
    padding: 30px 0;
    background-image: url("../../../assets/tc-landing/bg-video.svg");
    background-position: 0 45%;
    background-repeat: no-repeat;
    height: 600px;
  }

  .embed-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-container {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .section4 {
    flex-direction: column;
  }

  .steps-row {
    flex-direction: column;
    align-items: center;
  }

  .step-container {
    width: 100%;
    padding: 15px 30px;
    margin-top: 0;
  }

  .steps-label {
    display: none;
  }

  .how-it-works {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--dark);
  }

  .section2 {
    margin-top: 30px;
    background-image: url("../../../assets/tc-landing/section2-left.svg"),
      url("../../../assets/tc-landing/section2-right.svg");
    background-position: center left -60px, top right -60px;
    background-repeat: no-repeat;
    background-size: 9rem, 7rem;
  }

  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    margin-right: 0;
  }

  .footer-label-containter {
    width: 40%;
  }

  .action-button {
    width: 100%;
  }

  .section3-action-button {
    width: 80%;
  }

  .button-group {
    width: 40%;
  }

  /* standardize buttons type full */
  .link-filled-size {
    min-width: initial;
    max-width: 100%;
  }

  .step-label {
    font-size: 22px;
  }

  .footer-c {
    flex-direction: column;
    gap: 0.2rem;
  }

  .card-header-title {
    font-size: 24px;
  }

  .card-header-text {
    font-size: 16px;
  }

  .logo {
    max-width: 100%;
  }

  .graphic-container img {
    max-width: 100%;
    margin-top: 40px;
  }

  .card-container {
    min-height: max-content;
  }
}

@media only screen and (min-width: 2560px) {
  .button-group {
    width: 10%;
  }
}

@media only screen and (max-width: 1440px) {
  .button-group {
    width: 20%;
  }
}

/** TABLET **/
@media only screen and (max-width: 768px) {
  .button-group {
    width: 35%;
  }
}
</style>
